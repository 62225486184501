
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        



























































































































































































.rates {
  ::v-deep .table-content__header__main .price,
  ::v-deep .advantage {
    display: none;
  }
  ::v-deep .section-hero-light__content {
    display: block;
  }

  ::v-deep .table__layout__table {
    grid-row-end: 4;
  }
}

.rates__hero {
  padding-top: 0;

  @include mq(m) {
    padding-top: $spacing;
  }

  @include mq(l) {
    padding-top: $spacing * 4;
  }
}

.rates_selector {
  display: flex;
  flex-wrap: wrap;

  @include mq(m) {
    justify-content: center;
  }
}

.rates_selector__choice {
  &:first-child {
    width: 100%;
  }

  @include mq($until: 'l') {
    margin-bottom: $spacing;
  }

  @include mq(l) {
    margin-right: 3rem;
    margin-bottom: $spacing / 2;

    &:last-child {
      margin-right: 0;
    }
  }
}

.rates_selector__choice__title {
  @extend %fw-bold;

  width: 100%;
  margin: 0 1rem 0 0;
  font-family: $ff-alt;
  font-size: 1.6rem;
}

.rates_selector__choice__action {
  display: flex;

  div {
    margin-right: 1.5rem;
  }

  label {
    margin-left: 1rem;
  }
}

.rates_selector__choice__rgus {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
  margin-top: $spacing / 2;
  margin-bottom: $spacing;

  @include mq(m) {
    gap: 1rem;
  }
}

.rates_selector__choice__rgus__item {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mq(m) {
    flex-direction: row;
    padding: 1.5rem 2rem;

    ::v-deep .selectable-block__check {
      margin-right: 0;
      margin-left: auto;
    }
  }
}

.rates_selector__choice__rgus__item__title {
  @extend %fw-bold;

  margin: 0 0 1rem;
  font-size: 1.6rem;
  line-height: 2rem;
  text-transform: uppercase;

  @include mq(m) {
    margin: 0;
    text-transform: none;
  }
}

.rates-wrapper {
  position: relative;
  min-height: 100vh;
}

.rates-loading {
  @include get-all-space;

  padding-top: 10rem;
  display: flex;
  justify-content: center;
  background-color: white;
}

.rates-loading-inner {
  img {
    width: 5rem;
  }
}

.rates__pack__title {
  text-align: center;
}

.rates__pack__table {
  padding-top: 0;

  @include mq(m) {
    margin-top: $spacing * 3;
  }

  ::v-deep {
    .table__layout {
      padding-top: 0;
    }
  }
}

// Transition
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
