
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        











































































































$width: 80%;
$max-width: 100rem;

.table {
  position: relative;
  padding-top: $spacing * 2;
  padding-bottom: $spacing * 2;

  @include mq(m) {
    padding-top: $spacing * 4;
    padding-bottom: $spacing * 4;
  }

  @include mq(xl) {
    padding-top: $spacing * 6;
    padding-bottom: $spacing * 6;
  }
}

.table-desktop,
[class*='table-desktop--'] {
  @include mq(l) {
    &[class*='--solo'] {
      width: $width;
      max-width: $max-width;
      margin: 0 auto;
    }
  }
}

.table__layout,
[class*='table__layout--'] {
  @include center-x;

  z-index: -1;
  top: 0;
  grid-gap: 1rem;
  width: 100%;
  height: 100%;
  padding-top: $spacing * 2;
  padding-bottom: $spacing * 2;

  @include mq(m) {
    padding-top: $spacing * 4;
    padding-bottom: $spacing * 4;
  }

  @include mq(l) {
    &[class*='--solo'] {
      width: $width;
      max-width: $max-width;
      margin: 0 auto;
    }
  }

  @include mq(xl) {
    display: grid;
    grid-template-rows: 27rem 1fr 5rem;
    grid-template-columns: 31% 34% 33.3%;
    padding-top: $spacing * 6;
    padding-bottom: $spacing * 6;

    .net-comparison-table & {
      padding-bottom: $spacing * 5;
    }

    &[class*='--solo'] {
      grid-template-columns: 1fr 35rem;
    }

    .is-solo & {
      grid-template-columns: 30% 22.5% 22.5% 22.5%;
    }

    .pack & {
      grid-template-rows: 30rem 1fr 12rem;
    }

    .rates & {
      grid-template-rows: 18rem 1fr 10rem;
    }
  }

  > div {
    box-shadow: 0 0 20px rgba($black, 0.2);
    border-radius: 1rem;
  }
}

.table__layout__col,
[class*='table__layout__col--'] {
  grid-row-start: 1;
  grid-row-end: 4;
  grid-column: 4;
  background: $white;

  &[class*='table__layout__col--'][class*='--solo'] {
    grid-column: 2;
  }
}

.table__layout__col:nth-child(2) {
  grid-column: 3;
  background: $white;
}

.table__layout__col:nth-child(3) {
  grid-column: 2;
  background: $white;
}

.table__layout__table {
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 4;
  margin-right: 1rem;
  background: transparent;

  [class*='table__layout--'][class*='--solo'] & {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

.table-content,
[class*='table-content--'] {
  [class*='table-desktop--solo'] & {
    width: 100%;
  }
}

.table-desktop,
[class*='table-desktop--'] {
  .table-content__header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }

  &[class*='--solo'] {
    .table-content__header__block {
      position: relative;
      @include mq($until: xl) {
        width: 100%;
      }

      @include mq(xl) {
        width: 35rem;
      }
    }
  }

  &.is-solo {
    .table-content__order__button-outer + .table-content__order__button-outer {
      margin-left: 1rem;
    }
  }
}

.table-content__header {
  @include mq($until: xl) {
    position: sticky;
    z-index: layer(high);
    top: 1rem;
    width: 100%;
    background-color: $white;
  }
}

.table-content__header__sale {
  position: absolute;
  z-index: 15;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  transform: translate(0, -50%);

  @include mq($until: 'xl') {
    display: none;
  }
}

.table-content__header__main {
  position: relative;
  z-index: 10;
  flex: 1 auto;
  padding: $spacing * 2 $spacing $spacing * 0.5;
  text-align: center;

  @include mq(m) {
    padding: $spacing * 1.75 $spacing * 1.5 $spacing * 0.5;
  }
}

.table-content__header__block {
  position: relative;
  width: 49%;
  margin-right: 1.5rem;
  border-radius: 1rem 1rem 0 0;

  @include mq(xl) {
    width: 34%;
    height: 27rem;
    margin-right: 0;

    .pack & {
      height: 30rem;
    }

    .rates & {
      height: 18rem;
    }
  }

  .selection__products,
  .product-card__pricing {
    @include mq($until: m) {
      display: none;
    }
  }

  .is-solo & {
    width: 32%;

    @include mq(xl) {
      width: 23%;
      margin-right: 0;
    }
  }

  .product-card__message {
    @include mq($until: 'xl') {
      padding: 0;
    }
  }
}

.table-content__data__item-inner {
  width: 100%;
}

.table-content__data-outer {
  overflow: hidden;
}

.table-content__order {
  display: flex;
  justify-content: flex-end;
  min-height: 7rem;
}

.table-content__order__button-outer {
  display: flex;
  justify-content: center;
  width: 50%;
  padding: 4rem 0;

  @include mq(xl) {
    width: 34.5%;
  }

  .is-solo & {
    width: 32%;

    &:first-child {
      display: flex;
    }

    @include mq(xl) {
      width: 22.5%;
    }
  }

  [class*='table-desktop--solo'] & {
    width: 100%;

    &:first-child {
      display: flex;
    }

    @include mq(xl) {
      width: 35rem;
      // margin-right: 35%;
    }
  }
}
