
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        











































































































.table-content__header__sale {
  position: absolute;
  z-index: 15;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  transform: translate(0, -50%);

  @include mq($until: 'xl') {
    display: none;
  }
}

.table-content__header__main {
  position: relative;
  z-index: 10;
  flex: 1 auto;
  width: 100%;
  padding: $spacing * 2 $spacing $spacing * 0.5;
  text-align: center;

  ::v-deep .price__stack__months {
    @extend %fw-normal;

    color: inherit;
  }

  @include mq(m) {
    padding: $spacing * 1.75 $spacing * 1.5 $spacing * 0.5;
  }
}
